import { Component, Prop, Vue } from 'vue-property-decorator'
import { S3PathType } from '@/models/api/s3PathType'
import _ from 'lodash'

@Component
export default class ImageAbstractMixin extends Vue {
  @Prop({ default: 1 })
  pageNumber!: number

  @Prop({ default: 0 })
  pageIndex!: number

  /**
   * カリキュラムモードコード
   *
   * @return カリキュラムモードコード
   */
  protected get curriculumModeCode(): string {
    return Array.isArray(this.$route.query.curriculumModeCode)
      ? this.$route.query.curriculumModeCode[0] || ''
      : this.$route.query.curriculumModeCode || ''
  }

  /**
   * Web APIを通して取得した画像URLを取得
   * 問題ページの場合も配列に1つの連想配列が渡ってくる
   * [{d: '...', m: '...', j: '...'}]
   *
   * @return 画像URL群
   */
  private get imageUrls(): any[] {
    return this.$store.getters['drills/imageUrls']
  }

  /**
   * Web APIを通して取得した画像名を取得
   * 問題ページの場合も配列に1つの連想配列が渡ってくる
   * [{d: '...', m: '...', j: '...'}]
   *
   * @return 画像名群
   */
  protected get imagePaths(): any[] {
    return this.$store.getters['drills/imagePaths']
  }

  /**
   * Web APIを通して取得した音声URLを取得
   *
   * @return 音声URLの配列
   */
  protected get audioUrls() {
    // drills/audioUrls: [['url1', 'url2', ...], ['url1', 'url2'...]]
    const audioUrls = this.$store.getters['drills/audioUrls']
    if (this.pageIndex === null) return []
    if (!audioUrls) return []
    if (!audioUrls[this.pageIndex]) return []

    return audioUrls[this.pageIndex]
  }

  /**
   * 読解文の画像URLを取得
   *
   * @return 読解文の画像URLの配列
   */
  protected get dokkai(): string[] {
    if (this.pageIndex === null) return []
    if (!this.imageUrls) return []
    if (!this.imageUrls[this.pageIndex]) return []
    if (!this.imageUrls[this.pageIndex].d) return []

    return this.imageUrls[this.pageIndex].d
  }

  /**
   * 指示文の画像URLを取得
   *
   * @return 指示文の画像URLの文字列。指示文の場合は1つのみ
   */
  protected get siji(): string | null {
    if (this.pageIndex === null) return null
    if (!this.imageUrls) return null
    if (!this.imageUrls[this.pageIndex]) return null

    return this.imageUrls[this.pageIndex].j
  }

  /**
   * 問題文の画像URLを取得
   *
   * @return 問題文の画像URLの配列
   */
  protected get mondai(): any[] {
    if (this.pageIndex === null) return []
    if (!this.imageUrls) return []
    if (!this.imageUrls[this.pageIndex]) return []

    return this.imageUrls[this.pageIndex].m
  }

  /**
   * 問題が大問かどうか取得
   *
   * @return 大問かどうかの真偽値
   */
  protected get isLProblem(): boolean {
    if (this.pageIndex === null) return false
    return this.$store.getters['drills/isLProblems'][this.pageIndex]
  }

  /**
   * 問題が小問かどうか取得
   *
   * @return 小問かどうかの真偽値
   */
  private isSProblem(): boolean {
    return !this.isLProblem
  }

  /**
   * 小問画像が一つ目かどうか
   *
   * @return 小問かどうかの真偽値
   */
  protected isSProblemFirstImage(s3_path_type: S3PathType, imageIndex: number): boolean {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3_path_type + '0[2-9].png$')
    const filename = this.imagePaths[this.pageIndex][s3_path_type][imageIndex]

    return this.isSProblem() && !regexp.test(filename)
  }

  /**
   * 大問・小問コード
   *
   * @return 大問・小問コード
   */
  protected get sProblemCodes() {
    return this.$store.getters['drills/sProblemCodes'][this.pageIndex] || []
  }

  /**
   * 問題名(入試のみ取得可能)
   *
   * @return 問題名
   */
  protected get sProblemNames() {
    return this.$store.getters['drills/sProblemNames'][this.pageIndex] || []
  }

  protected get processed(): boolean {
    return this.$store.getters['drills/processed']
  }

  /**
   * 各ページの大問毎の設問数
   *
   * @return 設問数
   */
  protected get questionNumberWithSProblem(): number[] {
    if (this.pageIndex === null) return []
    return this.$store.getters['drills/questionNumberWithSProblem'][this.pageIndex]
  }

  /**
   * 入力または選択したテキスト
   *
   * @return 入力または選択したテキストの配列
   */
  protected get inputSelectText(): any[] {
    return this.$store.getters['drills/inputSelectText']
  }

  protected pageQuestionStartNumber(end: number): number {
    return this.questionNumberWithSProblem.slice(0, end).reduce((sum, element) => sum + element, 0) + 1
  }

  protected answerFormat(mondaiIndex: number): string {
    if (this.pageIndex === null) return ''

    switch (this.$store.getters['drills/answerFormat'][this.pageIndex][mondaiIndex]) {
      case 1:
        return 'keyboard'
      case 2:
        return 'select'
      default:
        return ''
    }
  }

  /**
   * 問題の選択肢を取得
   *
   * @return 問題文の選択肢の配列
   */
  protected selectTags(mondaiIndex: number): string[] {
    return this.$store.getters['drills/selectTags'][this.pageIndex][mondaiIndex]
  }

  protected clickSelectTag(mondaiIndex: number, selectIndex: number): void {
    if (_.isEmpty(this.$refs.selects)) return
    const selects: any = this.$refs.selects

    this.inputSelectText[this.pageIndex][mondaiIndex] = this.selectTags(mondaiIndex)[selectIndex]
    this.$store.commit('drills/setInputSelectText', this.inputSelectText)

    selects.forEach((element: any, i: number) => {
      element.classList.remove('select__box-active')
      if (i === selectIndex) element.classList.add('select__box-active')
    })
  }

  protected updateInputSelectText(event: Event, mondaiIndex: number): void {
    this.inputSelectText[this.pageIndex][mondaiIndex] = (event.target as HTMLInputElement).value
    this.$store.commit('drills/setInputSelectText', this.inputSelectText)
  }
}
