

















































































import { Component, Prop, Watch, Vue, Mixins } from 'vue-property-decorator'
import { Debounce } from 'vue-debounce-decorator'
import PenCanvas from '@/components/modules/drills/atoms/PenCanvas.vue'
import ImageMixin from '@/components/modules/drills/molecules/ImageMixin.ts'
import { S3PathType } from '@/models/api/s3PathType'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'
import _ from 'lodash'

// 設問番号の表示にローカル変数を宣言（時間に余裕あれば変更する）
let _sProblemIndex = 0

@Component({
  components: {
    PenCanvas,
    TooltipButton,
  },
})
export default class ImageVertical extends Mixins(ImageMixin) {
  @Prop({ default: 0 })
  pageIndex!: number

  private canvasNameRight = 'canvasRight'
  private canvasNameLeft = 'canvasLeft'

  private handleResize = _.throttle(this.changeCanvasSize, 500)

  private mounted() {
    Vue.prototype.$logger.info('-- ImageVertical mounted')
    window.addEventListener('resize', this.handleResize)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  private get hasDokkai(): boolean {
    return this.dokkai.length > 0
  }

  private get refs(): any {
    return this.$refs
  }

  /**
   * ローカル変数の取得（共通化不可）
   */
  protected getSProblemIndex(): number {
    return _sProblemIndex
  }

  /**
   * ローカル変数のセット（共通化不可）
   */
  protected setSProblemIndex(value: number): void {
    _sProblemIndex = value
  }

  /**
   * ローカル変数のインクリメント（共通化不可）
   */
  protected sProblemIndexMethod(s3_path_type: S3PathType, imageIndex: number): void {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3_path_type + '0[2-9].png$')
    const filename = this.imagePaths[this.pageIndex][s3_path_type][imageIndex]

    // 問題インデックス
    if (!regexp.test(filename)) _sProblemIndex++
  }

  @Watch('processed')
  onProcessedChanged(newValue: boolean, oldValue: boolean) {
    Vue.prototype.$logger.info('-- onProcessedChanged')

    if (newValue) {
      // 読解文エリア、問題文エリアのスクロールを一番右にする
      this.$nextTick()
      this.refs['right-block']?.scrollTo(Number.MAX_SAFE_INTEGER, 0)
      this.refs['left-block']?.scrollTo(Number.MAX_SAFE_INTEGER, 0)
    }
  }

  @Debounce(300)
  private changeCanvasSize(): void {
    const leftBlock = this.refs['left-block']
    const rightBlock = this.refs['right-block']

    // canvasサイズ設定
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameLeft], this.calculateCanvasSize(leftBlock))
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameRight], this.calculateCanvasSize(rightBlock))
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }

    const imgs = Array.prototype.slice.call(element.getElementsByTagName('img'))
    const titles = Array.prototype.slice.call(element.getElementsByClassName('image__title'))
    let [width, height] = [0, 0]
    imgs.forEach((e: HTMLElement) => {
      // padding分 10px足しておく
      width += e.clientWidth + 10
      height = e.clientHeight
    })
    titles.forEach((e: HTMLElement) => {
      // margin分 30px足しておく
      width += e.clientWidth + 30
    })
    return { width, height }
  }

  private setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }
}
