



























































































import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ImageMixin from '@/components/modules/drills/molecules/ImageMixin.ts'
import { S3PathType } from '@/models/api/s3PathType'
import PlayAudioButon from '@/components/molecules/PlayAudioButon.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import PenCanvas from '@/components/modules/drills/atoms/PenCanvas.vue'
import { Debounce } from 'vue-debounce-decorator'
import _ from 'lodash'
import TooltipButton from '@/components/modules/drills/atoms/TooltipButton.vue'

// 設問番号の表示にローカル変数を宣言（時間に余裕あれば変更する）
let _sProblemIndex = 0

@Component({
  components: {
    PlayAudioButon,
    ScrollGuide,
    PenCanvas,
    TooltipButton,
  },
})
export default class ImageHorizontal extends Mixins(ImageMixin) {
  @Prop({ default: 0 })
  pageIndex!: number

  @Prop({ default: true })
  isShowAudio!: boolean

  private canvasNameRight = 'canvasRight'
  private canvasNameLeft = 'canvasLeft'

  /**
   * ローカル変数の取得（共通化不可）
   */
  protected getSProblemIndex(): number {
    return _sProblemIndex
  }

  /**
   * ローカル変数のセット（共通化不可）
   */
  protected setSProblemIndex(value: number): void {
    _sProblemIndex = value
  }

  /**
   * ローカル変数のインクリメント（共通化不可）
   */
  protected sProblemIndexMethod(s3_path_type: S3PathType, imageIndex: number): void {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3_path_type + '0[2-9].png$')
    const filename = this.imagePaths[this.pageIndex][s3_path_type][imageIndex]

    // 問題インデックス
    if (!regexp.test(filename)) _sProblemIndex++
  }

  private get hasDokkai(): boolean {
    return this.dokkai.length > 0
  }

  private handleResize = _.throttle(this.changeCanvasSize, 500)

  private mounted() {
    window.addEventListener('resize', this.handleResize)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  @Debounce(300)
  private changeCanvasSize(): void {
    const leftBlock = this.$refs['left-block'] as HTMLElement
    const rightBlock = this.$refs['right-block'] as HTMLElement

    // canvasサイズ設定
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameLeft], this.calculateCanvasSize(leftBlock))
    this.setCanvasSize(Vue.prototype.$penCanvases[this.canvasNameRight], this.calculateCanvasSize(rightBlock))
  }

  private calculateCanvasSize(element: HTMLElement): any {
    if (!element) return { width: 0, height: 0 }

    return { width: element.clientWidth, height: element.scrollHeight }
  }

  private setCanvasSize(canvas: any, size: { width: number; height: number }): void {
    if (!canvas) return

    canvas.changeSize({
      width: size.width,
      height: size.height,
    })
    canvas.setCanvasContext()
  }
}
